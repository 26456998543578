import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { MeFragmentFragmentDoc } from '../../../providers/networking/__generated__/me-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSelectedUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateSelectedUserMutation = { __typename?: 'Mutation', updateSelectedUser: { __typename?: 'User', id: string, username: string, about?: string | null, avatarAssetId?: string | null, displayName: string, email: string, isAdmin: boolean, interests: Array<string>, skills: Array<string>, projects: Array<string>, educations: Array<string>, badges: Array<string>, avatarAsset?: { __typename?: 'Asset', id: string, type: Types.AssetType, url: string } | null } };


export const UpdateSelectedUserDocument = gql`
    mutation UpdateSelectedUser($input: UpdateUserInput!) {
  updateSelectedUser(input: $input) {
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;
export type UpdateSelectedUserMutationFn = Apollo.MutationFunction<UpdateSelectedUserMutation, UpdateSelectedUserMutationVariables>;

/**
 * __useUpdateSelectedUserMutation__
 *
 * To run a mutation, you first call `useUpdateSelectedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectedUserMutation, { data, loading, error }] = useUpdateSelectedUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelectedUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelectedUserMutation, UpdateSelectedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelectedUserMutation, UpdateSelectedUserMutationVariables>(UpdateSelectedUserDocument, options);
      }
export type UpdateSelectedUserMutationHookResult = ReturnType<typeof useUpdateSelectedUserMutation>;
export type UpdateSelectedUserMutationResult = Apollo.MutationResult<UpdateSelectedUserMutation>;
export type UpdateSelectedUserMutationOptions = Apollo.BaseMutationOptions<UpdateSelectedUserMutation, UpdateSelectedUserMutationVariables>;