import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
export type MeFragmentFragment = { __typename?: 'User', id: string, username: string, about?: string | null, avatarAssetId?: string | null, displayName: string, email: string, isAdmin: boolean, interests: Array<string>, skills: Array<string>, projects: Array<string>, educations: Array<string>, badges: Array<string>, avatarAsset?: { __typename?: 'Asset', id: string, type: Types.AssetType, url: string } | null };

export const MeFragmentFragmentDoc = gql`
    fragment MeFragment on User {
  id
  username
  about
  avatarAsset {
    id
    type
    url
  }
  avatarAssetId
  displayName
  email
  isAdmin
  username
  interests
  skills
  projects
  educations
  badges
}
    `;