
import Scholarship from '../components/Scholarship'
import { useEffect, useState } from 'react'
import useShowToast from '../hooks/useShowToast';
import { Button, Flex, Text, Spinner } from '@chakra-ui/react'
import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';


const ScholarScoreboard = () => {

  const center = useBreakpointValue({
    base: "-30%", // iPhone
    sm: "10%",
    md: "-25%", // iPad Mini
    lg: "-20%",
    xl: "39%"
  });
  
  const moveheader = useBreakpointValue({
    base: "-7%", // iPhone
    sm: "10%",
    md: "-25%", // iPad Mini
    lg: "70%",
    xl: "39%"
  });
  
  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");
  
  const adjustedCenter = isIphoneXR ? "0%" : isIphone14ProMax ? "-5%" : isIpadPro ? "-20%" : center;
  const adjustedHeader = isIphoneXR ? "2%" : isIphone14ProMax ? "-5%" : isIpadPro ? "-20%" : moveheader;

  
  const [topUsers, setTopUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const showToast = useShowToast();

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await fetch('/api/posts/scholarship-winners');
        if (!response.ok) {
          throw new Error('Failed to fetch top users');
        }
        const data = await response.json();
        console.log('Fetched top users:', data); 
        setTopUsers(data);
      } catch (error) {
        setError(error.message);
        showToast(error.message, 'error');
      } finally {
        setLoading(false);
      }
    };
    fetchTopUsers();
  }, [showToast]);

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  const instructions = (
    <Box>
      <Heading as="h2" size="lg" fontWeight="bold" textAlign="center" mb={4}
            fontFamily={'Orbitron, sans-serif'} 
            color="rgb(135,206,250)">
        Competition Overview
      </Heading>
      <Text mb={4}       fontFamily={'Orbitron, sans-serif'} 
      >
        The ARK scholarship exists to encourage the development of and reward aesthetic and innovative projects among undergraduate and graduate STEM students through the usage of our STEM oriented social media application, ARK. The first prize scholarship will be $1,500.
      </Text>
      <Text mb={4}       fontFamily={'Orbitron, sans-serif'} 
     >
        ARK is a social media platform that has been made for people who wish to create and inspire. On ARK people can post about a plethora of different topics such as but not excluded to: engineering, computer science, biology, chemistry, physics, earth science, and medicine. ARK is home for all who look to learn, teach, and explore the STEM fields.
      </Text>
      
      <Heading as="h2" size="lg" fontWeight="bold" textAlign="center" mb={4}       fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)">
      Competition Rules
    </Heading>
    <Heading as="h3" size="md" fontWeight="bold" mb={2}       fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)">
      1. Eligibility:
    </Heading>
    <Text mb={4}       fontFamily={'Orbitron, sans-serif'} 
      >
      As stated above, this competition is open to undergraduate and graduate students in the STEM fields. We would especially like to encourage students and faculty of Franciscan University, though it will not be the only school the competition is open to.
      Projects can be done by individuals or teams (up to 5 members a team).
    </Text>
    <Heading as="h3" size="md" fontWeight="bold" mb={2}       fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)">
      2. Project Requirements:
    </Heading>
    <Text mb={4}       fontFamily={'Orbitron, sans-serif'} 
      >
      Think of this project like a glorified science fair that will be entirely hosted on ARK. We want to encourage any STEM major, but especially engineering, chemistry, biology, and computer science majors, to work on a specific project all semester.
      This submission can be a project that is assigned in a class, but with a little extra flare, or could be a project that is done independent of a class.
      In order to submit the project to the competition, it must conform to the following criteria:
    </Text>
    <UnorderedList mb={4}       fontFamily={'Orbitron, sans-serif'} 
     >
      <ListItem       fontFamily={'Orbitron, sans-serif'} 
     >The project must be made by the students in the STEM field. The student must put their name(s), school, and contact information.</ListItem>
      <ListItem       fontFamily={'Orbitron, sans-serif'} 
    >The project can either (1) solve a problem or answer a hypothesis or (2) be a demonstration of some scientific principle. For example, students could submit a software they are developing in a class which solves some issue. Or students could create a chatbot or some other invention and explain how they did it and how others can too.</ListItem>
      <ListItem      fontFamily={'Orbitron, sans-serif'} 
      >The students should make an ARK account and keep the community updated on their project. The student should post updates on their project twice a week. These posts can be in the form of text, images or video. The posts will have #Scholarship24 as a tag. The community will be seeing these posts and their feedback will play a role in the competition. Students should also make at least one reply to other posts per week.</ListItem>
      <ListItem       fontFamily={'Orbitron, sans-serif'} 
      >The projects, or at very least the posts, should display an aesthetic character. Professionals and students from across the country use the platform, so make it nice enough that when they see it they are impressed. Make your videos and photos on ARK as aesthetic as possible, as flare upon delivery will be highly regarded. An artistic bent is appreciated.</ListItem>
      <ListItem       fontFamily={'Orbitron, sans-serif'} 
     >The entry cut off is September 9th. Students must post between this date and the submission date. The final submission date is December 7th, when the students should submit their completed project, but we will be accepting submissions till as late as the 21st for those who wish to perfect their project and thus have a higher chance of winning.</ListItem>
      <ListItem       fontFamily={'Orbitron, sans-serif'} 
     >The winner will be announced in January.</ListItem>
    </UnorderedList>
    <Heading as="h3" size="md" fontWeight="bold" mb={2}       fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)">
      Judging Process:
    </Heading>
    <Text mb={4}       fontFamily={'Orbitron, sans-serif'} 
     >
      On ARK there will be a scoreboard where the top users who make posts with the #Scholarship24 tag will be displayed. Those users whose posts garner the most likes and positivity from the community will have a higher place on the scoreboard.
      Whoever has the highest place on the scoreboard on December 21st will be the winner of the scholarship.
      First prize will receive $1,500. Winning projects will be featured in company promotions and marketing materials.
    </Text>
  </Box>
);

console.log(topUsers[0]?.totalLikes);



  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "70%",
        }}
      >
        <Text
          fontFamily={"Orbitron, sans-serif"}
          color="black"
          fontSize="4xl"
          whiteSpace="nowrap"
          style={{ WebkitTextStroke: "1px rgb(135,206,250)" }}
        >
          Scoreboard
        </Text>
      </div>
      <div
        left={adjustedCenter}
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          overflowWrap: "break-word",
        }}
      >
        <Scholarship
          name={
            <Box>
              <span
                style={{ fontFamily: "Orbitron, sans-serif", fontSize: "16px" }}
              >
                Scholarship
              </span>
              <span
                style={{
                  fontFamily: "Orbitron, sans-serif",
                  fontSize: "24px",
                  textShadow:
                    "0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white",
                }}
              >
                24
              </span>
            </Box>
          }
          prize="1500"
          description="ARK's fall 2024 scholarship!"
          Avatar1={topUsers[0]?.user?.avatarAsset?.url || "default-avatar-url"}
          Avatar2={topUsers[1]?.user?.avatarAsset?.url || "default-avatar-url"}
          Avatar3={topUsers[2]?.user?.avatarAsset?.url || "default-avatar-url"}
          Avatar4={topUsers[3]?.user?.avatarAsset?.url || "default-avatar-url"}
          Avatar5={topUsers[4]?.user?.avatarAsset?.url || "default-avatar-url"}
          user1={topUsers[0]?.user?.displayName || "N/A"}
          user2={topUsers[1]?.user?.displayName || "N/A"}
          user3={topUsers[2]?.user?.displayName || "N/A"}
          user4={topUsers[3]?.user?.displayName || "N/A"}
          user5={topUsers[4]?.user?.displayName || "N/A"}
          school1={topUsers[0]?.user?.organization || "----"}
          school2={topUsers[1]?.user?.organization || "----"}
          school3={topUsers[2]?.user?.organization || "----"}
          school4={topUsers[3]?.user?.organization || "----"}
          school5={topUsers[4]?.user?.organization || "----"}
          score1={topUsers[0]?.user?.totalLikes || "----"}
          score2={topUsers[1]?.user?.totalLikes || "----"}
          score3={topUsers[2]?.user?.totalLikes || "----"}
          score4={topUsers[3]?.user?.totalLikes || "----"}
          score5={topUsers[4]?.user?.totalLikes || "----"}
          username1={topUsers[0]?.user?.username || "N/A"}
          username2={topUsers[1]?.user?.username || "N/A"}
          username3={topUsers[2]?.user?.username || "N/A"}
          username4={topUsers[3]?.user?.username || "N/A"}
          username5={topUsers[4]?.user?.username || "N/A"}
          Instructions={instructions}
        />
      </div>
    </>
  );
};

export default ScholarScoreboard;